import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2023/nlng-upgrades-bed/1.jpeg';
import blogd2 from '../../assets/images/2023/nlng-upgrades-bed/2.jpeg';
import blogd3 from '../../assets/images/2023/nlng-upgrades-bed/3.jpeg';
import blogd4 from '../../assets/images/2023/nlng-upgrades-bed/11.jpeg';
import blogd5 from '../../assets/images/2023/nlng-upgrades-bed/5.jpeg';
import blogd6 from '../../assets/images/2023/nlng-upgrades-bed/6.jpeg';
import blogd7 from '../../assets/images/2023/nlng-upgrades-bed/7.jpeg';
import blogd8 from '../../assets/images/2023/nlng-upgrades-bed/8.jpeg';
import blogd9 from '../../assets/images/2023/nlng-upgrades-bed/9.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  NLNG upgrades 120-bed Obstetrics and Gynaecology block at LUTH
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Nigeria LNG, NLNG, Monday, commissioned the renovation and
                  upgrade of the Obstetrics and Gynaecology (Ward C) Block of
                  the Lagos University Teaching Hospital, LUTH, following it's
                  keen interest on making the country, Nigeria, a destination
                  for world class healthcare delivery.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Renovation of Ward C
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The project offers 120 beds, a waiting room, nurse stations,
                  private wards, consulting rooms, radiation room, doctors and
                  nurse call rooms is a facelift project that will aid in
                  premium prenatal and postnatal services.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  At the visitation
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Speaking at the event, NLNG’s Managing Director and Chief
                  Executive Officer, Dr Philip Mshelbila, represented by General
                  Manager, External Relations and Sustainable Development, Mr.
                  Andy Odeh, explained that the facilities were upgraded to
                  provide quality medical care for Nigerians. <br />
                  “This facility was a four-storey structure in a moribund state
                  but it has been re-constructed, its facilities fully upgraded
                  and properly equipped to boast of a first-rate Obstetrics and
                  Gynecology consulting centre anywhere in the world,” he said.
                  <br></br>
                  He added that the ward will help in addressing the
                  infrastructure shortfall of the hospital, noting that health
                  care delivery is a core aspect of the firm’s corporate social
                  responsibility.
                  <br></br>
                  His words: “More facilities have been provided for the teeming
                  number of patients in this metropolitan city of Lagos; while a
                  more congenial environment has been provided for dispensation
                  of efficient healthcare delivery systems. These are part of
                  the grounds for our setting out to implement HSP in our
                  tertiary health institutions.
                  <br></br>
                  “We are delighted to be part of progress in giving more
                  Lagosians better healthcare. As Denzel Washington said ‘it’s
                  not about what you have or even what you’ve accomplished. It’s
                  about who you’ve lifted up, who you’ve made better. It’s about
                  what you’ve given back.’
                  <br></br>
                  “Healthcare delivery is one of the four pillars of Nigeria LNG
                  Corporate Social Responsibility interventions. The rest are
                  education, infrastructure and empowerment. We have always
                  anchored our implementation strategy on partnership and stake
                  holding.
                  <br></br>
                  “While Nigeria NLNG continues to deliver significant
                  socio-economic value through the export of Nigeria’s vast
                  natural gas resources, its vision of being a globally
                  competitive LNG company, helping to build a better Nigeria,
                  inspires every CSR intervention which it has sponsored.”
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  inspections
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  On his part, Minister of State for Health, Mr. Ekumankama
                  Nkama, thanked the initiators and managers of the project for
                  a proper execution, adding that the ward, which was built
                  about 60 years ago had not undergone any major renovation. 
                  <br></br>
                  “We hope for more from your organisation and any other similar
                  organisations. I am delighted that NLNG has made efforts to
                  ensure good healthcare delivery. Qualitative healthcare cannot
                  be achieved by the government alone, we solicit assistance
                  from multinational companies,” he said. 
                  <br></br>
                  Also speaking, Acting Chief Medical Director, LUTH, Professor Wasiu Adeyemo,
                  appealed to well-meaning Nigerians, corporate bodies and
                  multinational companies to extend their corporate social
                  responsibilities to the institution. 
                  <br></br>
                  “On behalf of the board of management, staff and students of LUTH, and Nigerians in
                  general, I would like to publicly appreciate this singular
                  effort by Nigeria LNG to contribute their own quota as part of
                  their CSR to improve the facilities in our hospital. I am
                  aware this project is among the 6 projects in Phase 1 of their
                  efforts. I want to plead that LUTH is remembered when
                  subsequent phases of your efforts in improving facilities in
                  our hospital begin. We shall be forever grateful,” he
                  concluded.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd8} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
